import {FieldProps} from "ra-ui-materialui/src/field/types";
import {useRecordContext} from "react-admin";
import {Chip, Stack} from "@mui/material";

export enum BlogItemOnListEnum {
    OFF_LINE = 0,
    ONL_INE = 1,
    PIN_TO_TOP = 100,
}

const BlogItemStatusFiled =({source}: FieldProps) => {
    const record = useRecordContext();

    const redenderContentType = (status: number) => {
        switch (status) {
            case BlogItemOnListEnum.OFF_LINE:
                return '下線';
            case BlogItemOnListEnum.ONL_INE:
                return '上線';
            case BlogItemOnListEnum.PIN_TO_TOP:
                return '置頂';
            default:
                return 'Unknown';
        }
    }

    return record ? (
        <Stack direction={"row"} spacing={0}>
            {
                record[source] === BlogItemOnListEnum.OFF_LINE && (
                    <Chip color={"warning"} key={record.id} label={redenderContentType(record[source])} />
                )
            }
            {
                record[source] === BlogItemOnListEnum.ONL_INE && (
                    <Chip color={"success"} key={record.id} label={redenderContentType(record[source])} />
                )
            }
            {
                record[source] === BlogItemOnListEnum.PIN_TO_TOP && (
                    <Chip color={"secondary"} key={record.id} label={redenderContentType(record[source])} />
                )
            }
        </Stack>
    ) : null;

}

export default BlogItemStatusFiled;