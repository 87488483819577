import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import MCampaignList from "./MCampaignList";
import MCampaignCreate from "./MCampaignCreate";
import MCampaignEdit from "./MCampaignEdit";

export default {
    list: MCampaignList,
    create: MCampaignCreate,
    edit: MCampaignEdit,
    icon: CampaignOutlinedIcon,
};
