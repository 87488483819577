import {
    Edit,
    FileField,
    FileInput,
    ImageField,
    ImageInput,
    ListButton,
    Loading,
    required,
    SaveButton,
    SelectArrayInput, SimpleForm,
    TextInput,
    Toolbar, useEditContext, useEditController,
    useGetList
} from "react-admin";
import UndoIcon from '@mui/icons-material/Undo';
import {Card, CardContent, Divider, Grid, Tab, Tabs} from "@mui/material";
import * as React from "react";
import {useCallback, useEffect} from "react";
import {BlogItemContentTypeEnum} from "../components/BlogItemContentTypeField";
import {
    DefaultEditorOptions,
    RichTextInput
} from "ra-input-rich-text";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import HomeRepairServiceOutlinedIcon from '@mui/icons-material/HomeRepairServiceOutlined';
import { Editor } from '@tiptap/core';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import AddLinkOutlinedIcon from '@mui/icons-material/AddLinkOutlined';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';
import SmartDisplayOutlinedIcon from '@mui/icons-material/SmartDisplayOutlined';
import {useParams} from "react-router";

const BlogCtrbItemToolbar = ({category, contentType, editor}: {category: number, contentType: number, editor: Editor | null}) =>{
    const { saving } = useEditContext();

    const transformBlogCtrbItemData = useCallback((data: any) =>{
        if(contentType === BlogItemContentTypeEnum.NOTE){
            return data.coverImgFile?.rawFile ?  {
                itemId: data.itemId,
                category: category,
                title: data.title,
                coverImgFile: data.coverImgFile?.rawFile,
                contentType: BlogItemContentTypeEnum.NOTE,
                txtContent: data.txtContent,
                tags: data.tags,
                srcContent: editor?.getJSON() ? JSON.stringify(editor?.getJSON()) : null,
            } :  {
                itemId: data.itemId,
                category: category,
                title: data.title,
                contentType: BlogItemContentTypeEnum.NOTE,
                txtContent: data.txtContent,
                tags: data.tags,
                srcContent: editor?.getJSON() ? JSON.stringify(editor?.getJSON()) : null,
            }
        } else if(contentType === BlogItemContentTypeEnum.WEBLINK){
            return data.coverImgFile?.rawFile ? {
                itemId: data.itemId,
                category: category,
                title: data.title,
                coverImgFile: data.coverImgFile?.rawFile,
                contentType: BlogItemContentTypeEnum.WEBLINK,
                weblink: data.weblink,
                txtContent: data.txtContent,
                tags: data.tags,
                srcContent: data.txtContent,
            } : {
                itemId: data.itemId,
                category: category,
                title: data.title,
                contentType: BlogItemContentTypeEnum.WEBLINK,
                weblink: data.weblink,
                txtContent: data.txtContent,
                tags: data.tags,
                srcContent: data.txtContent,
            }
        } else if(contentType === BlogItemContentTypeEnum.VIDEO){
            if(data.coverImgFile?.rawFile && data.videoFile?.rawFile){
                return {
                    itemId: data.itemId,
                    category: category,
                    title: data.title,
                    coverImgFile: data.coverImgFile?.rawFile,
                    videoFile: data.videoFile?.rawFile,
                    contentType: BlogItemContentTypeEnum.VIDEO,
                    txtContent: data.txtContent,
                    tags: data.tags,
                    srcContent: data.txtContent,
                }
            } else if(data.coverImgFile?.rawFile){
                return {
                    itemId: data.itemId,
                    category: category,
                    title: data.title,
                    coverImgFile: data.coverImgFile?.rawFile,
                    contentType: BlogItemContentTypeEnum.VIDEO,
                    txtContent: data.txtContent,
                    tags: data.tags,
                    srcContent: data.txtContent,
                }
            } else if(data.videoFile?.rawFile){
                return {
                    itemId: data.itemId,
                    category: category,
                    title: data.title,
                    videoFile: data.videoFile?.rawFile,
                    contentType: BlogItemContentTypeEnum.VIDEO,
                    txtContent: data.txtContent,
                    tags: data.tags,
                    srcContent: data.txtContent,
                }
            } else {
                return {
                    itemId: data.itemId,
                    category: category,
                    title: data.title,
                    contentType: BlogItemContentTypeEnum.VIDEO,
                    txtContent: data.txtContent,
                    tags: data.tags,
                    srcContent: data.txtContent,
                }
            }
        } else if(contentType === BlogItemContentTypeEnum.YOUTUBE) {
            return data.coverImgFile?.rawFile ? {
                itemId: data.itemId,
                category: category,
                title: data.title,
                coverImgFile: data.coverImgFile?.rawFile,
                contentType: BlogItemContentTypeEnum.YOUTUBE,
                weblink: data.weblink,
                txtContent: data.txtContent,
                tags: data.tags,
                srcContent: data.txtContent,
            } : {
                itemId: data.itemId,
                category: category,
                title: data.title,
                contentType: BlogItemContentTypeEnum.YOUTUBE,
                weblink: data.weblink,
                txtContent: data.txtContent,
                tags: data.tags,
                srcContent: data.txtContent,
            }
        }
    },[contentType, category, editor])

    return (
        <Toolbar>
            <Grid container>
                <Grid item xs={6}>
                    <SaveButton transform={transformBlogCtrbItemData} type="button"/>
                </Grid>
                <Grid item xs={6} style={{textAlign: 'right'}}>
                    <ListButton size={"medium"} label="GO BACK" disabled={saving} icon={<UndoIcon/>} variant={"outlined"} />
                </Grid>
            </Grid>
        </Toolbar>
    )
};

const BlogItemContentTypeTabs = [
    { id: 1, name: '文章', icon: <EventNoteOutlinedIcon/> },
    { id: 2, name: '書籤', icon: <AddLinkOutlinedIcon/> },
    { id: 3, name: '影片', icon: <VideoLibraryOutlinedIcon/> },
    { id: 4, name: 'YouTube', icon: <SmartDisplayOutlinedIcon/> }
];

const BlogItemCategoryTabs = [
    { id: 1, name: '部落格投稿', icon: <BookOutlinedIcon/> },
    { id: 2, name: '第三方工具或設計資源投稿', icon: <BuildCircleOutlinedIcon/> },
    { id: 3, name: '開箱文投稿', icon: <HomeRepairServiceOutlinedIcon/> }
];

const BlogCtrbItemEdit =() =>{

    const { id } = useParams();
    const { record, isPending: editing } = useEditController({ resource: 'blogctrbitems', id });

    const { data, isPending, error } = useGetList(
        'blogtags',
    );

    const [categoryTabIdx, setCategoryTabIdx] = React.useState(1);
    const [contentTypeTabIdx, setContentTypeTabIdx] = React.useState(1);
    const editorRef = React.useRef<Editor | null>(null);

    useEffect(() => {
        if (record) {
            setCategoryTabIdx(record.category);
            setContentTypeTabIdx(record.contentType);
        }
    }, [record]);

    const categoryTabHandleChange = useCallback(
        (event: React.ChangeEvent<{}>, value: any) => {
            setCategoryTabIdx(value);
        },
        [setCategoryTabIdx]
    );

    const contentTypeTabHandleChange = useCallback(
        (event: React.ChangeEvent<{}>, value: any) => {
            setContentTypeTabIdx(value);
        },
        [setContentTypeTabIdx]
    );

    if (isPending || editing) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }

    const validateURL = (value: string) => {
        const pattern = new RegExp('^(https?:\\/\\/)?'+ // 协议
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // 域名
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // 或 IP (v4) 地址
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // 端口和路径
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // 查询字符串
            '(\\#[-a-z\\d_]*)?$','i'); // hash标签
        if (!pattern.test(value)) {
            return '請輸入有效的鏈接地址';
        }
        return undefined;
    };

    return (
        <Card sx={{mt: 2}}>
            <Tabs
                variant="fullWidth"
                centered
                value={categoryTabIdx}
                indicatorColor="primary"
                onChange={categoryTabHandleChange}
            >
                { BlogItemCategoryTabs.map(choice => (
                    <Tab
                        key={choice.id}
                        label={
                            <span style={{display: 'flex',alignItems: 'center'}}>
                                {choice.icon}
                                {choice.name}
                            </span>
                        }
                        value={choice.id}
                    />
                ))}
            </Tabs>
            <Divider/>
            <CardContent>
                <Edit mutationMode={"pessimistic"}>
                    <Tabs
                        variant="fullWidth"
                        centered
                        value={contentTypeTabIdx}
                        indicatorColor="primary"
                        onChange={contentTypeTabHandleChange}
                    >
                        { BlogItemContentTypeTabs.map(choice => (
                            <Tab
                                key={choice.id}
                                label={
                                    <span style={{display: 'flex',alignItems: 'center'}}>
                                {choice.icon}
                                        {choice.name}
                            </span>
                                }
                                value={choice.id}
                            />
                        ))}
                    </Tabs>
                    <Divider/>
                    {
                        contentTypeTabIdx === 1 && (
                            <SimpleForm toolbar={<BlogCtrbItemToolbar category={categoryTabIdx} contentType={contentTypeTabIdx} editor={editorRef.current}/>} warnWhenUnsavedChanges>
                                <TextInput name="title" source="title" label="文章標題" validate={required()} multiline
                                           resettable/>
                                <RichTextInput
                                    editorOptions={{
                                        ...DefaultEditorOptions,
                                        onCreate: ({ editor }: { editor: Editor }) => {
                                            editorRef.current = editor;
                                        }
                                    }}
                                    sx={{
                                        '& .tiptap': {
                                            minHeight: '15em',
                                        },
                                    }} fullWidth={true} name="txtContent" source="txtContent"
                                    label="文章內容" validate={required()}/>
                                <SelectArrayInput source="tags" label="標簽(最多10組)"
                                                  optionValue="tagId" name="tags"
                                                  optionText="tagName" choices={data}/>
                                <ImageInput source="coverImgFile" name="coverImgFile"
                                            label="文章封面(最小尺寸為[312x160px]支持JPG、JPEG、PNG檔案大小限製:3MB)"
                                            validate={required()}
                                            placeholder={<p><FileUploadOutlinedIcon/>上傳檔案</p>}>
                                    <ImageField source="src" title="title"/>
                                </ImageInput>
                            </SimpleForm>
                        )
                    }
                    {
                        contentTypeTabIdx === 2 && (
                            <SimpleForm toolbar={<BlogCtrbItemToolbar category={categoryTabIdx} contentType={contentTypeTabIdx} editor={editorRef.current}/>} warnWhenUnsavedChanges>
                                <TextInput name="title" source="title" label="文章標題" validate={required()} multiline
                                           resettable/>
                                <TextInput name="weblink" source="weblink" resettable label="書籤連結"
                                           validate={[required(), validateURL]}/>
                                <TextInput name="txtContent" multiline resettable rows={7} source="txtContent" label="影片說明"
                                           validate={required()}/>
                                <SelectArrayInput source="tags" label="標簽(最多10組)"
                                                  optionValue="tagId" name="tags"
                                                  optionText="tagName" choices={data}/>
                                <ImageInput source="coverImgFile" name="coverImgFile"
                                            label="文章封面(最小尺寸為[312x160px]支持JPG、JPEG、PNG檔案大小限製:3MB)"
                                            validate={required()}
                                            placeholder={<p><FileUploadOutlinedIcon/>上傳檔案</p>}>
                                    <ImageField source="src" title="title"/>
                                </ImageInput>
                            </SimpleForm>
                        )
                    }
                    {
                        contentTypeTabIdx === 3 && (
                            <SimpleForm toolbar={<BlogCtrbItemToolbar category={categoryTabIdx} contentType={contentTypeTabIdx} editor={editorRef.current}/>} warnWhenUnsavedChanges>
                                <TextInput name="title" source="title" label="文章標題" validate={required()} multiline
                                           resettable/>
                                <FileInput source="videoFile" label={"影音檔上傳(檔案大小須小於30MB)"}
                                           placeholder={<p><FileUploadOutlinedIcon/>上傳影音檔案</p>}
                                           name="videoFile">
                                    <FileField source="src" title="title"/>
                                </FileInput>
                                <TextInput name="txtContent" multiline resettable rows={7} source="txtContent" label="影片說明"
                                           validate={required()}/>
                                <SelectArrayInput source="tags" label="標簽(最多10組)"
                                                  optionValue="tagId" name="tags"
                                                  optionText="tagName" choices={data}/>
                                <ImageInput source="coverImgFile" name="coverImgFile"
                                            label="文章封面(最小尺寸為[312x160px]支持JPG、JPEG、PNG檔案大小限製:3MB)"
                                            validate={required()}
                                            placeholder={<p><FileUploadOutlinedIcon/>上傳檔案</p>}>
                                    <ImageField source="src" title="title"/>
                                </ImageInput>
                            </SimpleForm>
                        )
                    }
                    {
                        contentTypeTabIdx === 4 && (
                            <SimpleForm toolbar={<BlogCtrbItemToolbar category={categoryTabIdx} contentType={contentTypeTabIdx} editor={editorRef.current}/>} warnWhenUnsavedChanges>
                                <TextInput name="title" source="title" label="文章標題" validate={required()} multiline
                                           resettable/>
                                <TextInput name="weblink" source="weblink" label="YouTube連結"
                                           validate={required()}/>
                                <TextInput name="txtContent" multiline resettable rows={7} source="txtContent" label="影片说明"
                                           validate={required()}/>
                                <SelectArrayInput source="tags" label="標簽(最多10組)"
                                                  optionValue="tagId" name="tags"
                                                  optionText="tagName" choices={data}/>
                                <ImageInput source="coverImgFile" name="coverImgFile"
                                            label="文章封面(最小尺寸為[312x160px]支持JPG、JPEG、PNG檔案大小限製:3MB)"
                                            validate={required()}
                                            placeholder={<p><FileUploadOutlinedIcon/>上傳檔案</p>}>
                                    <ImageField source="src" title="title"/>
                                </ImageInput>
                            </SimpleForm>
                        )
                    }
                </Edit>
            </CardContent>
        </Card>
    )
}

export default BlogCtrbItemEdit;