import * as React from 'react';
import { Layout } from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';
import {useEffect} from "react";

export default ({ children }: { children: React.ReactNode }) => {

    useEffect(() => {
        document.title = "JColor CMS System";
    }, []);

    return (
        <Layout appBar={AppBar} menu={Menu}>
            {children}
        </Layout>
    )
};
