import {FieldProps} from "ra-ui-materialui/src/field/types";
import {useRecordContext} from "react-admin";

export enum BlogItemCategoryEnum {
    BLOG = 1,
    TOOL = 2,
    UNBOXING = 3,
    OTHER = 5,
}

const BlogItemCategoryField =({source}: FieldProps) => {
    const record = useRecordContext();

    const redenderContentType = (status: number) => {
        switch (status) {
            case BlogItemCategoryEnum.BLOG:
                return '部落格';
            case BlogItemCategoryEnum.TOOL:
                return '工具介紹';
            case BlogItemCategoryEnum.UNBOXING:
                return '開箱文';
            case BlogItemCategoryEnum.OTHER:
                return '其他';
            default:
                return 'Unknown';
        }
    }

    return record ? (
        <span>
            {redenderContentType(record[source])}
        </span>
    ) : null;

}

export default BlogItemCategoryField;