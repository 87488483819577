import React, {Fragment, useCallback, useState} from 'react';
import {
    BulkDeleteButton,
    Button, Confirm,
    Count,
    CreateButton,
    DatagridConfigurable,
    EditButton,
    FunctionField,
    List,
    Loading,
    SearchInput, SelectColumnsButton,
    TextField,
    TopToolbar, useListContext,
    useNotify,
    useRefresh,
    useUnselect,
    useUpdateMany,
} from 'react-admin';
import {Divider, Tab, Tabs, Theme, useMediaQuery} from "@mui/material";
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import VerticalAlignTopOutlinedIcon from '@mui/icons-material/VerticalAlignTopOutlined';
import MobileGrid from "../orders/MobileGrid";
import BlogRelatedTagsField from "../components/BlogRelatedTagsField";

const ListActions = () => (
    <TopToolbar>
        <CreateButton />
        <SelectColumnsButton/>
    </TopToolbar>
);

const tabs = [
    { id: -1, name: '全部' },
    { id: 0, name: '一般標簽' },
    { id: 1000, name: '熱門標簽' },
];

const tagFilters = [
    <SearchInput source="tagName" alwaysOn placeholder="中文標籤名"  name="tagName" key="tagName" />
];

const CustomBulkActionButtons = () => {
    const {resource, selectedIds } = useListContext();
    const [type, setType] = useState<number>(0);
    const [open, setOpen] = useState(false);
    const notify = useNotify();
    const refresh = useRefresh();
    const unselect = useUnselect(resource);

    const [updateMany, { isPending: typing}] = useUpdateMany(
        'blogtags/type',
        { ids: selectedIds, data: {tagIds: selectedIds, type: type}}
    );

    const setBlogTagsType = (type: number) =>{
        setType(type);
        setOpen(true);
    }

    const handleDialogClose = () => setOpen(false);
    const handleConfirm = () => {
        updateMany('blogtags/type',{ids: selectedIds, data: {tagIds: selectedIds, type: type}},{
            onSuccess: () => {
                unselect(selectedIds);
                setOpen(false);
                refresh();
                notify('Element update', {type: 'info'});
            },
            onError: (error) => {
                unselect(selectedIds);
                setOpen(false);
                notify(`Element ${error}`, {type: 'warning'});
            }
        });
    };

    return (
       <>
           <Button label="設為熱門標簽" disabled={typing} variant={"outlined"} startIcon={<VerticalAlignTopOutlinedIcon />} color={"warning"} onClick={()=>{setBlogTagsType(1000)}} />
           <Button label="設為一般標簽" disabled={typing} variant={"outlined"} startIcon={<BookmarkOutlinedIcon />} onClick={()=>{setBlogTagsType(0)}}/>
           <BulkDeleteButton mutationMode="pessimistic" variant={"outlined"}
               confirmTitle="刪除選中標簽"
               confirmContent="您確定要刪除選中的標簽嗎？"
           />
           <Confirm
               isOpen={open}
               loading={typing}
               title="設定選中標簽"
               content={`您確定要設定選中的標簽設定為: ${type===1000?'熱門標簽':'一般標簽'}嗎？`}
               onConfirm={handleConfirm}
               onClose={handleDialogClose}
           />
       </>
    );
}

const BlogTagsList = () =>(
    <List
        filterDefaultValues={{ type: -1 }}
        sort={{ field: 'tagId', order: 'DESC' }}
        perPage={10}
        actions={<ListActions />}
        filters={tagFilters}>
        <BlogTagsDatagrid />
    </List>
)

const BlogTagsDatagrid = () => {
    const { filterValues, setFilters, displayedFilters, isPending, error} = useListContext();
    const isXSmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );

    const handleChange = useCallback(
        (event: React.ChangeEvent<{}>, value: any) => {
            setFilters &&
            setFilters(
                { ...filterValues, type: value },
                displayedFilters
            );
        },
        [displayedFilters, filterValues, setFilters]
    );

    if (isPending) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }

    return (
        <Fragment>
            <Tabs
                variant="fullWidth"
                centered
                value={filterValues.type}
                indicatorColor="primary"
                onChange={handleChange}
            >
                {tabs.map(choice => (
                    <Tab
                        key={choice.id}
                        label={
                            <span>
                                {choice.name} (
                                <Count
                                    filter={{
                                        ...filterValues,
                                        type: choice.id,
                                    }}
                                    sx={{lineHeight: 'inherit',color: 'red'}}
                                />
                                )
                            </span>
                        }
                        value={choice.id}
                    />
                ))}
            </Tabs>
            <Divider/>
            {isXSmall ? (
                <MobileGrid />
            ) : (
                <>
                    {
                        filterValues.type === -1 && (
                            <DatagridConfigurable bulkActionButtons={<CustomBulkActionButtons/>} rowClick={false}>
                                <TextField source="id" label="標簽ID"/>
                                <TextField source="tagName" label="中文標籤名"/>
                                <FunctionField
                                    source="type"
                                    label="標籤類型"
                                    render={record => `${record.type === 1000 ? '熱門標簽' : '一般標簽'}`}
                                />
                                <BlogRelatedTagsField source="relatedTags" label="標簽關聯標簽"/>
                                <EditButton label="編輯"/>
                            </DatagridConfigurable>
                        )
                    }
                    {
                        filterValues.type === 0 && (
                            <DatagridConfigurable bulkActionButtons={<CustomBulkActionButtons/>} rowClick={false}>
                                <TextField source="id" label="標簽ID"/>
                                <TextField source="tagName" label="中文標籤名"/>
                                <FunctionField
                                    label="標籤類型"
                                    render={record => `${record.type === 1000 ? '熱門標簽' : '一般標簽'}`}
                                />
                                <BlogRelatedTagsField source="relatedTags" />
                                <EditButton/>
                            </DatagridConfigurable>
                        )
                    }
                    {
                        filterValues.type === 1000 && (
                            <DatagridConfigurable bulkActionButtons={<CustomBulkActionButtons/>} rowClick={false}>
                                <TextField source="id" label="標簽ID"/>
                                <TextField source="tagName" label="中文標籤名"/>
                                <FunctionField
                                    label="標籤類型"
                                    render={record => `${record.type === 1000 ? '熱門標簽' : '一般標簽'}`}
                                />
                                <BlogRelatedTagsField source="relatedTags" />
                                <EditButton/>
                            </DatagridConfigurable>
                        )
                    }
                </>
            )
            }
        </Fragment>
    );
};

export default BlogTagsList;
