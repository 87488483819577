import * as React from 'react';
import { useState } from 'react';
import { Box } from '@mui/material';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import BookIcon from '@mui/icons-material/Book';
import CategoryIcon from '@mui/icons-material/Bookmark';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';

import {
    useTranslate,
    MenuItemLink,
    MenuProps,
    useSidebarState,
} from 'react-admin';

import visitors from '../visitors';
import orders from '../orders';
import products from '../products';
import categories from '../categories';
import reviews from '../reviews';
import SubMenu from './SubMenu';

type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers';

const Menu = ({ dense = false }: MenuProps) => {
    const [state, setState] = useState({
        menuCatalog: true,
        menuSales: true,
        menuCustomers: true,
    });
    const [open] = useSidebarState();

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <Box
            sx={{
                width: open ? 200 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: theme =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            <SubMenu
                handleToggle={() => handleToggle('menuSales')}
                isOpen={state.menuSales}
                name="Blog管理"
                icon={<BookIcon />}
                dense={dense}
            >
                <MenuItemLink
                    to="/blogctrbitems"
                    state={{ _scrollToTop: true }}
                    primaryText={"投稿文章"}
                    leftIcon={<EventNoteOutlinedIcon />}
                    dense={dense}
                />
                <MenuItemLink
                    to="/blogitems"
                    state={{ _scrollToTop: true }}
                    primaryText={"Blog文章"}
                    leftIcon={<BookIcon />}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuCatalog')}
                isOpen={state.menuCatalog}
                name="Blog標籤管理"
                icon={<CategoryIcon />}
                dense={dense}
            >
                <MenuItemLink
                    to="/blogtags"
                    state={{ _scrollToTop: true }}
                    primaryText={"標籤管理"}
                    leftIcon={<CategoryIcon />}
                    dense={dense}
                />
            </SubMenu>
            <SubMenu
                handleToggle={() => handleToggle('menuCatalog')}
                isOpen={state.menuCatalog}
                name="行銷活動管理"
                icon={<CampaignOutlinedIcon />}
                dense={dense}
            >
                <MenuItemLink
                    to="/mcampaigns"
                    state={{ _scrollToTop: true }}
                    primaryText={"活動管理"}
                    leftIcon={<CampaignOutlinedIcon />}
                    dense={dense}
                />
            </SubMenu>
        </Box>
    );
};

export default Menu;
