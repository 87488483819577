import * as React from 'react';
import { Menu, MenuItem, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FieldProps } from "ra-ui-materialui/src/field/types";
import {useRecordContext, useRedirect} from "react-admin";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MarkUnreadChatAltOutlinedIcon from '@mui/icons-material/MarkUnreadChatAltOutlined';

const MCampaignRowActions = ({ source }: FieldProps) => {
    const record = useRecordContext();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const redirect = useRedirect();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        redirect(`/mcampaigns/${record?.id}`);
        handleClose();
    };

    const handleRedirect = () => {
        redirect(`/mcampaigns/${record?.id}/mcampaignlogs`);
        handleClose();
    };

    return (
        <>
            <IconButton aria-label="more" onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleEdit}><EditOutlinedIcon/>編輯</MenuItem>
                <MenuItem onClick={handleRedirect}><MarkUnreadChatAltOutlinedIcon/>用戶留言(<span style={{color: "red"}}>{record?.logCount | 0}</span>)</MenuItem>
            </Menu>
        </>
    );
};

export default MCampaignRowActions;