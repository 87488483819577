import React, { useEffect, useRef } from 'react';

const RecaptchaComponent = ({ sitekey, onVerify, onExpire }: {sitekey: string, onVerify?: (token: string) => void, onExpire?: ()=>void}) => {
    const recaptchaRef = useRef(null);

    useEffect(() => {
        //@ts-ignore
        if (window.grecaptcha) {
            //@ts-ignore
            window.grecaptcha.ready(()=>{
                //@ts-ignore
                window.grecaptcha.render(recaptchaRef.current, {
                    'sitekey': sitekey,
                    'callback': handlerVerify,
                    'expired-callback': handlerExpire,
                });
            });
        }
    }, [sitekey]);

    const handlerVerify = (token: any) => {
        onVerify?.(token);
    };

    const handlerExpire = () => {
        onExpire?.();
    }

    return (
        <div ref={recaptchaRef}></div>
    );
};

export default RecaptchaComponent;
