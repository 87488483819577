import {FieldProps} from "ra-ui-materialui/src/field/types";
import {Loading, useGetList, useRecordContext} from "react-admin";
import {Chip, Stack} from "@mui/material";
import React from "react";

const BlogRelatedTagsField =({source}: FieldProps) => {
    const record = useRecordContext();
    const { data, isPending, error } = useGetList(
        'blogtags',
    );
    if (isPending) { return <Loading />; }
    if (error) { return <p>ERROR</p>; }

    const blogTags = data?.filter(item => record?.relatedTags.includes(item.tagId));
    return blogTags ? (
        <Stack direction="row" spacing={1}>
            {
                blogTags.map((tag) => (<Chip color={"secondary"} key={tag.id} label={tag.tagName} />))
            }
        </Stack>
    ) : null;

}

export default BlogRelatedTagsField;