import {useRecordContext} from "react-admin";
import {FieldProps} from "ra-ui-materialui/src/field/types";

export enum BlogItemContentTypeEnum {
    NOTE = 1,
    WEBLINK = 2,
    VIDEO = 3,
    YOUTUBE = 4,
    OTHER = 5,
}

const BlogItemContentTypeField =({source}: FieldProps) => {
    const record = useRecordContext();

    const redenderContentType = (status: number) => {
        switch (status) {
            case BlogItemContentTypeEnum.NOTE:
                return '文章';
            case BlogItemContentTypeEnum.WEBLINK:
                return '書籤';
            case BlogItemContentTypeEnum.VIDEO:
                return '影片';
            case BlogItemContentTypeEnum.YOUTUBE:
                return 'YouTube';
            case BlogItemContentTypeEnum.OTHER:
                return '其他';
            default:
                return 'Unknown';
        }
    }

    return record ? (
        <span>
            {redenderContentType(record[source])}
        </span>
    ) : null;

}

export default BlogItemContentTypeField;